<template>
  <b-row class="p-0 m-1 mb-8">
    <b-card class="col-12">
      <h3>{{$t('similar_accounts')}}</h3>
      <div class="row">
        <div v-for="(similar_account, index) in similars_accounts" :key="index" class="d-flex col-12 col-sm-6 col-lg-3 my-1">
          <b-avatar class="mr-1 padding-03" :src="similar_account.basic.avatar_url" size="60px"></b-avatar>
          <div>
            <strong>{{similar_account.basic.username}}</strong>
            <span class="d-block">{{utils.getFormat(similar_account.metrics.subscribers_count.value)}} {{$t('campaigns.followers')}} </span>
            <b-link :href="`/perfil/${network}/${similar_account.basic.username}`" target="_blank">{{$t('preview.view_report')}}</b-link>
          </div>
        </div>
      </div>
    </b-card>
  </b-row>
</template>

<script>
import { 
  BRow,
  BCard,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import profile_services from '@/services/profile';
import utils from '@/libs/utils';

export default {
  components: {
    BRow,
    BCard,
    BAvatar,
    BLink
  },
  data() {
    return {
      similars_accounts: {},
      utils,
      network: this.$route.params.network
    }
  },
  created() {
    this.getSimilarAccounts();
  },
  methods: {
    getSimilarAccounts() {
      profile_services.getSimilarAccountsNoSession(this.$route.params.network, this.$route.params.alias).then((response) => {
        this.similars_accounts = response.data.bloggers;
      });
    }
  }
};
</script>
<style>
.mb-8 {
   margin-bottom: 8em !important;
}
</style>
