<template>
  <b-row :class="`main-container-preview col-12 p-0 m-0`">
    <b-col cols="12" class="p-0 m-0">
      <div v-if="errors" class="col-12">
        <h1 class="text-center col-12">
          <feather-icon icon="AlertTriangleIcon" size="100" class="col-12 mb-2"></feather-icon>
          {{$t('preview.no_results')}}
          <b-button variant="primary" class="d-block mx-auto mt-3" @click="$router.push({name: 'dashboard-analytics'})">Go to home</b-button>
        </h1>
      </div>
      <div v-else class="w-100">
        <header-profile-preview :influencer="formatDataHeader(influencer)"/>  
        <networks-charts :influencer="influencer" class="mb-2"/>
        <similar-accounts v-if="network === 'instagram' || network === 'tiktok'"/>

        <div class="container-nav-fixed">
          <b-button variant="primary" size="lg" class="button-free-report" @click="$router.push({name: 'auth-login'})">{{$t('preview.view_full_report')}}</b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { 
  BRow, 
  BButton, 
  BCol 
} from "bootstrap-vue";
import HeaderProfilePreview from '@/views/pages/profile/preview/HeaderProfilePreview.vue';
import NetworksCharts from '@/views/pages/profile/preview/NetworksCharts.vue';
import SimilarAccounts from '@/views/pages/profile/preview/SimilarAccounts.vue';
import profile_services from '@/services/profile';
import utils from '@/libs/utils';

export default {
  components: {
    BRow,
    HeaderProfilePreview,
    NetworksCharts,
    SimilarAccounts,
    BButton,
    BCol
  },
  data() {
    return {
      influencer: {},
      network: this.$route.params.network,
      avatar: null,
      errors: false,
      utils
    }
  },
  created() {
    this.getPreviewProfile();
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
  },
  methods: {
    getPreviewProfile() {
      profile_services.getPreviewProfile(this.$route.params.network, this.$route.params.alias).then((response) => {
        if ((response.status && response.status >= 400) || Object.keys(response).length === 0) {
          this.errors = true;
        } else {
          this.influencer = response.info;
        }
      });
    },
    getAvatar(username, network) {
      const form_data = new FormData();
      form_data.append('username', username);
      form_data.append('network', network);
      profile_services.getProfileImageForNetworks(form_data).then((response) => {
        this.avatar = response.image;
      });
    },
    formatDataHeader(influencer) {
      this.getAvatar(this.network !== 'youtube' ? influencer.username : influencer.channel_id, this.network);
      const obj = {
        username: influencer.username,
        photo_url: this.avatar,
        is_verified: influencer.is_verified,
        full_name: influencer.full_name,
      }
      if (this.network === 'instagram') {
        obj['blogger_languages'] = influencer.blogger_languages;
        obj['location'] = influencer.location;
        obj['blogger_categories'] = influencer.blogger_categories;
        obj['followers'] = influencer.followers;
        obj['er'] = influencer.er.value;
      } else if (this.network === 'youtube') {
        obj['location'] = influencer.country.data.country;
        obj['blogger_categories'] = [influencer.category_name];
        obj['followers'] = influencer.subscribers.value;
        obj['er'] = influencer.er.performance['30d'].value;
      } else if (this.network === 'twitter') {
        obj['followers'] = influencer.followers ? influencer.followers.value : 0;
        obj['blogger_languages'] = influencer.languages ? influencer.languages.data : [];
        obj['er'] = influencer.er ? influencer.er.performance['30d'].value : 0;
        obj['location'] = (influencer.country && influencer.country.data !== null) ? influencer.country.data.country : null;
      } else if (this.network === 'twitch') {
        obj['blogger_languages'] = influencer.languages.data;
        obj['location'] = influencer.country.data.country;
        obj['er'] = null;
        obj['followers'] = influencer.followers.value;
      } else {
        obj['blogger_languages'] = influencer.languages.data;
        obj['followers'] = influencer.followers.value;
        obj['er'] = influencer.er.value;
        obj['location'] = influencer.country.data.country;
      }
      return obj;
    }
  }
};
</script>
<style>
.main-container-preview {
   width: 100%;
   margin: 0 auto;
   margin-top: 2em;
   margin-bottom: 9em;
}
.container-nav-fixed {
  width: 100%;
  height: 100px;
  position: fixed;
  background-color: white;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 0em -5em 5em #FAFAFA, 0 15px 25px rgb(16 17 19 / 9%), 0 -1px 10px rgb(24 26 28 / 7%);
  z-index: 10;
}
.button-free-report {
  /* margin: 0px auto !important; */
  height: 60px;
  margin-top: 1em;
}
</style>
