<template>
  <div class="col-12 p-0 m-0">
    <!-- INSTAGRAM GRAPHS -->

    <div v-if="network === 'instagram'" class="d-flex flex-wrap">
      <div class="col-12 col-md-6 d-flex flex-wrap align-items-stretch">
        <h3 class="mb-2 col-12">
          {{$t('preview.check_insta')}}
        </h3>
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1"> {{$t('tiktok.audience_demographs')}}</h5>
            <div class="col-12 mt-2 dots-p">
              <p>
                <span class="dots-text">{{$t('preview.audience_countries')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p>
                <span class="dots-text">{{$t('preview.audience_languages')}} </span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p>
                <span class="dots-text">{{$t('preview.full_audience')}} </span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p>
                <span class="dots-text">{{$t('preview.influencers_true')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_demography')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch flex-wrap" v-if="influencer.subscribers_growth_prc.performance">
        <h3 class="mb-2 col-12">
          {{$t('preview.check_fake')}}
        </h3>

        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.instagram_follower_growth')}} @{{influencer.username}}</h5>
            <div class="col-12 mt-2 dots-p">
              <div class="mb-1 mb-1">
                <span> {{$t('preview.followers_last_4')}} <span :class="influencer.subscribers_growth_prc.performance['30d'].value > 0 ? 'text-success' : 'text-danger'">
                  {{influencer.subscribers_growth_prc.performance['30d'].value > 0 ? '+' : ''}}
                  {{influencer.subscribers_growth_prc.performance['30d'].value}}%</span> 
                </span>

              </div>
              <p>
                <span class="dots-text">{{$t('preview.followe_unfollow')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p>
                <span class="dots-text">{{$t('preview.following_graph')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p>
                <span class="dots-text">{{$t('preview.organic_graph')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_grows_analysis')}} {{influencer.username}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('instagram.audienceInterest')}}</h5>
            <div class="col-12 mt-2 dots-p">
              <p 
                class="d-flex justify-content-between align-items-center"
                v-for="(interest, index) in influencer.audience_interests.slice(0, 3)"
                :key="index"
              >
                <span class="span-blank">{{interest[0]}}</span>
                <span class="span-blank">{{utils.decimalFixed(interest[1] * 100, 1)}}%</span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.full_audience_interest')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_what_audience')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.instagram_account_activity')}}</h5>
            <div class="col-12 mt-2 dots-p">
              <b-row>
                <b-col>
                  <span class="text-muted">{{$t('preview.avg_likes')}}</span>
                  <h3>{{utils.getFormat(influencer.avg_likes)}}</h3>
                </b-col>
                <b-col>
                  <span class="text-muted">{{$t('preview.avg_comments')}}</span>
                  <h3>{{utils.getFormat(influencer.avg_comments)}}</h3>
                </b-col>
              </b-row>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.engagement_analysis')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.engagement_authenticity')}} </span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.comment_pods_check')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.see_how_audience_engages')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>
    </div>

    <!-- YOUTUBE GRAPHS -->
    <div v-if="network === 'youtube'" class="d-flex flex-wrap">
      <div class="col-12">
        <b-card>
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.audience_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <b-row v-if="influencer.subscribers_growth_prc.performance" class="my-1">
                <b-col>
                  <strong class="">{{$t('preview.subscribers_growth')}}: </strong>
                  <h3>{{influencer.subscribers_growth_prc.performance['30d'].value}}%</h3>
                </b-col>
              </b-row>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_con')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_age_gender')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_languages')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_audience_analysis')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.content_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <b-row class="my-1">
                <b-col>
                  <span class="text-muted">{{$t('preview.avg_views')}}</span>
                  <h3>{{utils.getFormat(influencer.views_avg.performance['30d'].value_prev)}}</h3>
                </b-col>
                <b-col>
                  <span class="text-muted">{{$t('preview.views_in_30')}}</span>
                  <h3>{{utils.getFormat(influencer.views_count.value)}}</h3>
                </b-col>
              </b-row>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.comment_ratio')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.reaction_rate')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.like_comment_ratio')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.video_frequenc')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.video_performance')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.total_videos')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.most_recent_video')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_content_analysis')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.channel_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.channel_quality_scor')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.estimated_integration')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.channel_cm')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.earned_media_value')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.price_factor')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.virality')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_channel_analytics')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>
    </div>

    <!-- TWITTER GRAPHS -->
    <div v-if="network === 'twitter'" class="mw-100 col-12">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6">
          <b-card class="col-12">
            <b-row>
              <h5 class="border-bottom col-12 pb-1">{{$t('preview.audience_analytics_for')}} {{influencer.username}}</h5>
              <div class="col-12 dots-p">
                <b-row v-if="influencer.subscribers_growth_prc.performance" class="my-1">
                  <b-col>
                    <strong class="">{{$t('preview.subscribers_growth')}}: </strong>
                    <h3>{{influencer.subscribers_growth_prc.performance['30d'].value}}%</h3>
                  </b-col>
                </b-row>
                <p class="mb-1">
                  <span class="dots-text">{{$t('preview.follower_growth')}}</span>
                  <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                </p>
                <p class="mb-1">
                  <span class="dots-text">{{$t('preview.following_growth')}}</span>
                  <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                </p>

                <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                  {{$t('preview.view_audience_analysis')}}
                </b-button>
              </div>
            </b-row>
          </b-card>

          <b-card class="col-12">
              <b-row>
                <h5 class="border-bottom col-12 pb-1">{{$t('preview.content_analytics_for')}} {{influencer.username}}</h5>
                <div class="col-12 dots-p">
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.tweets_per_week')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.tweets_with_link')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.tweets_with_media')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.retweets_per_week')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.most_retweet')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>

                  <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                    {{$t('preview.view_content_analysis')}}
                  </b-button>
                </div>
              </b-row>
            </b-card>

            <b-card class="col-12">
              <b-row>
                <h5 class="border-bottom col-12 pb-1">{{$t('preview.account_analytics_for')}} {{influencer.username}}</h5>
                <div class="col-12 dots-p">
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.avg_engagement_tweet')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.retweet_received')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.replies_received')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <p class="mb-1">
                    <span class="dots-text">{{$t('preview.likes_received')}}</span>
                    <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
                  </p>
                  <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                    {{$t('preview.view_account_analytics')}}
                  </b-button>
                </div>
              </b-row>
            </b-card>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6">
          <timeline :id="influencer.username" sourceType="profile" :options="{ tweetLimit: '2' }" widget-class=""/>
        </b-col>
      </b-row>
    </div>

    <!-- TWITCH GRAPHS -->
    <div class="col-12 d-flex flex-wrap p-0" v-if="network === 'twitch'">
      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.account_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 d-flex justify-content-between flex-wrap">
              <div class="text-center col-12 col-lg-6 mb-3">
                <span class="d-block col-12">{{$t('campaigns.followers')}}</span>
                <strong>{{utils.getFormat(influencer.followers.value)}}</strong>
              </div>
              <div class="text-center col-12 col-lg-6 mb-3">
                <span class="d-block col-12">{{$t('preview.active_days_week')}}</span>
                <strong>{{influencer.active_days_per_week.performance['30d'].value}}</strong>
              </div>
              <div class="text-center col-12 col-lg-6 mb-3">
                <span class="d-block col-12">{{$t('preview.hours_streamed')}}</span>
                <strong>{{influencer.hours_streamed.performance['30d'].value}}</strong>
              </div>
              <div class="text-center col-12 col-lg-6 mb-3">
                <span class="d-block col-12">{{$t('preview.subscriber_growth_percentage')}}</span>
                <strong>{{influencer.subscribers_growth_prc.performance['30d'].value}}%</strong>
              </div>
            </div>
            <b-button class="mx-auto mt-2 outline-primary-fill" variant="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
              {{$t('preview.view_account_analytics')}}
            </b-button>
          </b-row>
        </b-card>
      </div>
      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <chart-twitch-preview :data="influencer.active_days_per_week.performance" />
        </b-card>
      </div>
    </div>

    <!-- TIKTOK GRAPHS -->
    <div v-if="network === 'tiktok'" class="d-flex flex-wrap">
      <div class="col-12">
        <b-card>
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.audience_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <b-row v-if="influencer.subscribers_growth_prc.performance" class="my-1">
                <b-col>
                  <strong class="">{{$t('preview.subscribers_growth')}} </strong>
                  <h3>{{influencer.subscribers_growth_prc.performance['30d'].value}}%</h3>
                </b-col>
              </b-row>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_con')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_age_gender')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.audience_languages')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_audience_analysis')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.content_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <b-row class="my-1">
                <b-col>
                  <span class="text-muted">{{$t('preview.avg_views')}}</span>
                  <h3>{{utils.getFormat(influencer.views_avg.value)}}</h3>
                </b-col>
                <b-col>
                  <span class="text-muted">{{$t('preview.views_in_30')}}</span>
                  <h3>{{utils.getFormat(influencer.likes_views_ratio.performance['30d'].value)}}</h3>
                </b-col>
              </b-row>
              <p class="mb-1">
                <span class="dots-text"> Hashtag</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.distribution_likes')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.likes_views_spread')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.post_frequency')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.most_viewed_commented')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>

              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_content_analysis')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>

      <div class="col-12 col-md-6 d-flex align-items-stretch">
        <b-card class="col-12">
          <b-row>
            <h5 class="border-bottom col-12 pb-1">{{$t('preview.account_analytics_for')}} {{influencer.username}}</h5>
            <div class="col-12 dots-p">
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.account_quality_scor')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.estimated_integration')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text"> {{$t('preview.account_cm')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <p class="mb-1">
                <span class="dots-text">{{$t('preview.earned_media_value')}}</span>
                <span class="container-lock-icon"><feather-icon icon="LockIcon" class="lock-icon-charts"/></span>
              </p>
              <b-button variant="outline-primary-fill" class="outline-primary-fill" @click="$router.push({name: 'auth-login'})">
                {{$t('preview.view_account_analytics')}}
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>
    </div>


  </div>
</template>

<script>
import {
  BRow,
  BCard,
  BButton,
  BCol
} from "bootstrap-vue";
import utils from '@/libs/utils';
import {Timeline} from 'vue-tweet-embed';
import ChartTwitchPreview from './ChartTwitchPreview.vue';

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCol,
    Timeline,
    ChartTwitchPreview,
  },
  props: {
    influencer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      utils,
      network: this.$route.params.network
    }
  }
};
</script>
<style>
.container-lock-icon {
  display: block;
  height: 1.6em;
  width: 1.6em;
  background-color: white;
  position: absolute;
  right: 0px;
  bottom: -3px;
}
.lock-icon-charts {

  background-color: white;
  width: 1.6em;
  height: 1.6em;
}
.dots-p p {
  background: inherit;
  margin: 0 auto;
  margin-bottom: 1em;
  position: relative;
}
.dots-p p:before {
  content: '';
  position: absolute;
  bottom: 0.2rem;
  width: 100%;
  height: 0;
  border-bottom: 2px dotted;
  border-color: #ccc;
}
.dots-text {
  background: inherit;
  display: inline;
  z-index: 1;
  padding-right: .2rem;
  position: relative;
  background-color: white;
  white-space: normal;
}
.span-blank {
  background-color: white;
  z-index: 1;
}

</style>